import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Instagram as LoadingState } from 'react-content-loader';
import {
  EmptyCard,
  FlexWrapper,
  SocialButtton,
  PostDetailWrapper
} from './StyledCampaignsSection';
import CampaignCard from './CampaignCard';
import Carousel from 'components/carousel/Carousel';
import VerticalBox from 'components/boxes/VerticalBox';
import EmptyCampaign from 'images/empty_states/empty_campaigns.png';
import NavigationLink from 'components/links/NavigationLink';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { getCampaingsActived } from 'services/rest/Campaings';
import {
  setListCampaigns,
  setLoading
} from 'redux/slices/Campaigns/CampaignsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectUserInfo } from 'redux/slices/userSlice';
import { ICampaigns } from 'redux/slices/Campaigns/Campaigns.interface';
import Button from 'components/buttons/Button';
import { createBonus } from 'services/rest/Bonus/createBonus';
import { getInfoUser } from 'services/rest/logout';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import { OptionTypeBase } from 'react-select';
import Calendar from 'components/Calendar';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import SocialMediaIcon from 'components/icons/SocialMediaIcon';
import { Colors } from 'styles/Constants';
import Modal from 'react-responsive-modal';
import {
  validFbUrl,
  validTwUrl,
  validLkUrl,
  validIgUrl,
  validTkUrl,
  validSnapUrl,
  validYtUrl,
  validThUrl,
  validOthersUrl
} from 'helpers/socialUrlValidate';

const CampaignsSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [socialNetwork, setSocialNetwork] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const urlPost = document.getElementById('url-input') as any;
  const [pendingPostMessage, setPendingPostMessage] = useState(false);
  const { t } = useTranslation(['campaign_section', 'campaigns_page']);
  const dispatch = useDispatch();
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const { currentUser } = useSelector(selectUser);
  const { infoUser } = useSelector(selectUserInfo);
  const { campaigns = [], loading } = useSelector(
    ({ campaigns }: any) => campaigns
  );
  const [userData, setUserData] = useState<any>(infoUser);
  const [beginDateCampaign, setBeginDateCampaign] = useState<any>();
  const [endDateCampaign, setEndDateCampaign] = useState<any>();
  const filterTypes = [
    { label: t('campaign_section:current'), value: 'current' },
    { label: t('campaign_section:recent'), value: 'recent' },
    { label: t('campaign_section:older'), value: 'older' },
    { label: t('campaign_section:custom'), value: 'custom' }
  ];
  const [filterType, setFilterType] = useState<OptionTypeBase>(filterTypes[0]);
  const onSelectCustomFilter = (startDate: Date, endDate: Date) => {
    const sDate = new Date(startDate).toISOString();
    const fDate = new Date(endDate).toISOString();
    setBeginDateCampaign(sDate);
    setEndDateCampaign(fDate);
  };
  const { company } = useSelector(selectCompany);

  const getData = async () => {
    const { data } = await getCampaingsActived(
      currentUser?.token,
      !company?.company_permission?.allowPendingCampaign,
      filterType.value,
      beginDateCampaign,
      endDateCampaign
    );
    dispatch(setListCampaigns(data.data));
    dispatch(setLoading(false));
  };

  const getUserData = async () => {
    const { data } = await getInfoUser(
      currentUser?.user?.id,
      currentUser?.token
    );
    if (data) {
      setUserData(data);
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getData();
    getUserData();
  }, [filterType, beginDateCampaign, endDateCampaign]);
  const campaignCards = () => {
    if (campaigns?.length > 0) {
      return campaigns.map((campaign: ICampaigns, index: number) => (
        <CampaignCard {...campaign} key={index} />
      ));
    }
    return [
      <EmptyCard key={1}>
        <VerticalBox
          className="empty_state"
          header={<img alt="EmptyCampaign" src={EmptyCampaign} />}
        >
          <h2>{t('campaign_section:empty_state_subtitle')}</h2>
          <p>{t('campaign_section:empty_state_description')}</p>
        </VerticalBox>
      </EmptyCard>
    ];
  };

  const handleIconClick = (icon: any, socialNetwork: any) => {
    setSelectedIcon(icon);
    setSocialNetwork(socialNetwork);
    setErrorMessage(false);
  };

  const handleCreateBonus = async (
    token: any,
    url: any,
    socialNetwork: any
  ) => {
    const { data, errors } = await await createBonus(token, url, socialNetwork);
    if (data) {
      setPendingPostMessage(true);
      setLoader(false);
    }
    if (errors) {
      setLoader(false);
    }
  };
  const onPublish = async () => {
    setLoader(true);
    if (socialNetwork === 'facebook' && validFbUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'twitter' && validTwUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'linkedin' && validLkUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'instagram' && validIgUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'tiktok' && validTkUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'snapchat' && validSnapUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'youtube' && validYtUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'threads' && validThUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else if (socialNetwork === 'otros' && validOthersUrl(urlPost.value)) {
      handleCreateBonus(currentUser?.token, urlPost.value, socialNetwork);
    } else {
      setErrorMessage(true);
      setLoader(false);
    }
  };
  return (
    <SectionWrapper>
      <FlexWrapper className="buttonCreateBonus">
        <h1 className="section-title">
          {campaigns?.length > 0
            ? t('campaign_section:active_campaigns')
            : t('campaign_section:empty_state_title')}
        </h1>
        {userData?.allowBonus && (
          <>
            <Button
              value={t('campaign_section:create_bonus')}
              variant="secondary"
              onClick={openModal}
            />
            <Modal
              open={isOpen}
              onClose={closeModal}
              center
              styles={{ modal: { textAlign: 'center' } }}
            >
              {isOpen && !pendingPostMessage && (
                <>
                  <div>
                    <div>
                      <p>{t('campaign_section:text_modal')}</p>
                      <span>
                        <b>{t('campaign_section:warning_text')}</b>
                      </span>
                    </div>
                    <div>
                      <p>{t('campaign_section:select_social_network')}</p>
                      <div>
                        <SocialButtton
                          className={selectedIcon === 'icon1' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon1', 'facebook')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="facebook"
                            color={Colors.facebook}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon2' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon2', 'twitter')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="twitter"
                            color={Colors.twitter}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon3' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon3', 'linkedin')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="linkedin"
                            color={Colors.linkedin}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon4' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon4', 'instagram')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="instagram"
                            color={Colors.instagram}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon5' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon5', 'tiktok')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="tiktok"
                            color={Colors.tiktok}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon6' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon6', 'snapchat')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="snapchat"
                            color={Colors.snapchat}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon7' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon7', 'youtube')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="youtube"
                            color={Colors.youtube}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon8' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon8', 'threads')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="threads"
                            color={Colors.threads}
                          />
                        </SocialButtton>
                        <SocialButtton
                          className={selectedIcon === 'icon9' ? 'selected' : ''}
                          onClick={() => handleIconClick('icon9', 'otros')}
                        >
                          <SocialMediaIcon
                            size="small"
                            name="otros"
                            color={Colors.otros}
                          />
                        </SocialButtton>
                      </div>
                    </div>
                    <p>Post URL:</p>
                    <input
                      id="url-input"
                      type="url"
                      className="bonus-box"
                      placeholder={t('campaign_section:post_url')}
                    />
                  </div>
                  {errorMessage && (
                    <p
                      className="error-message-challenge"
                      style={{ color: 'red', fontWeight: 'bold' }}
                    >
                      {t('campaign_section:error_message')}
                    </p>
                  )}
                  <Button
                    value={t('campaign_section:send')}
                    variant="tertiary"
                    onClick={onPublish}
                    loader={loader}
                  />
                </>
              )}
              {pendingPostMessage && (
                <PostDetailWrapper>
                  <p className="small-paragraph">
                    {t('campaign_section:created_bonus')}
                  </p>
                  <Button
                    value={t('campaign_section:participate_again')}
                    variant="success"
                    as="a"
                    size="medium"
                    target="_blank"
                    onClick={() => {
                      setIsOpen(true);
                      setPendingPostMessage(false);
                      setSelectedIcon(null);
                      setSocialNetwork(null);
                    }}
                  />
                </PostDetailWrapper>
              )}
            </Modal>
          </>
        )}
      </FlexWrapper>
      <FlexWrapper className="notranslate filtersForm">
        <h4>{t('campaign_section:title_order')} </h4>
        <DropdownSelect
          onSelect={option => setFilterType(option)}
          value={filterType}
          defaultValue={filterTypes}
          options={filterTypes}
        />
        {filterType.value === 'custom' && (
          <Calendar onChange={onSelectCustomFilter} />
        )}
      </FlexWrapper>
      {loading ? (
        <LoadingState height="230px" width="100%" />
      ) : (
        <Carousel
          items={campaignCards()}
          startIndex={0}
          className="campaign-ambassador"
        />
      )}
      <NavigationLink
        text={t('campaign_section:see_all')}
        direction={'forward'}
        to={'/campaigns'}
      />
    </SectionWrapper>
  );
};

export default CampaignsSection;
