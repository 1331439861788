import styled, { css } from 'styled-components';
import { Colors } from 'styles/Constants';

export const BaseStyles = css`
  transition: all 0.5s ease-out 0s;
  font-family: 'Montserrat', sans-serif;
  background-color: ${Colors.normalWhite};
  border: 1px solid ${Colors.primaryLightGray};
  color: ${Colors.normalBlack};
  font-size: 0.8125rem;
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: 400;
  max-width: 100%;
  display: block;
  outline: none;
  width: 100%;

  &[type='radio'],
  &[type='submit'],
  &[type='checkbox'] {
    height: auto;
    width: auto;
  }

  &:focus {
    border: 1px solid ${Colors.primaryDarkBlue};
  }

  &:disabled {
    background: ${Colors.secondaryLightGray};
    font-weight: 500;
    color: #000;
  }

  &.field-error {
    border: 1px solid ${Colors.inputError};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`;

const BaseInput = styled.input`
  ${BaseStyles}
`;

export default BaseInput;
