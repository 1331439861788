import Client from 'config/axios';

export const createManualReport = async (
  token: string,
  id: string,
  type: string
) => {
  try {
    const { data } = await Client.post(
      '/notification/manual-reminder',
      {
        type: type,
        id: id
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return { data: data };
  } catch ({ response }) {
    const result = response as any;
    return { errors: result.data };
  }
};
