import React, { useEffect, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import iconPerson from 'images/icons/person.svg';
import iconMoney from 'images/icons/money.svg';
import iconHeart from 'images/icons/heart.svg';
import EmployeeAvatar from 'images/avatars/employee.png';
import {
  BodyWrapper,
  MetricsWrapper,
  HeardMargin,
  StyleTable,
  ButtonStyled
} from './Styled';
import {
  StyledTooltipWrapper,
  TooltipMessage
} from 'components/TooltipWrapper/StyledTooltipWrapper';
import MetricBox from 'components/boxes/MetricBox';
import MetricImgBox from 'components/boxes/MetricBoxImg';
import SingleDataBox from 'components/boxes/SingleDataBox';
import NetworkParticipationBox from 'components/boxes/NetworkParticipationBox';
import { useDispatch, useSelector } from 'react-redux';
import { getReport } from 'services/rest/Report/getReport';
import { selectUser } from 'redux/slices/userSlice';
import DetailChallengeTable from './DetailChallengeTable';
import { getGroupAmbassador } from 'services/rest/GetGroupAmbassador/GetGroupAmbassador';
import { sortGroups } from 'helpers/groupsHelper';
import {
  selectGroupAmbassador,
  setData,
  setLoadingGroupAmbassador
} from 'redux/slices/GroupAmbassador/GroupAmbassadorSlice';
import { IGroupAmssador } from 'redux/slices/GroupAmbassador/GroupAmbassador.interface';
import { nFormatter } from 'helpers/formatHelper';
import moment from 'moment';
import 'moment/locale/es';
import { selectCompany } from 'redux/slices/Company/CompanySlice';
import Button from 'components/buttons/Button';
import { ErrorAlert, SuccessAlert } from 'components/Alert';
import { getCurrentCompany } from 'helpers/companyHelper';
import { createManualReport } from 'services/rest/Report/createManualReport';

interface DetailChallengeProps {
  challenge: any;
  allParticipants: any;
  socialNetworks: any;
}

const DetailChallengeAdmin: React.FC<DetailChallengeProps> = props => {
  const { challenge, allParticipants, socialNetworks } = props;
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [
    successReminderAlertVisible,
    setSuccessReminderAlertVisible
  ] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const { currentUser } = useSelector(selectUser);
  const { t } = useTranslation([
    'admin_campaign_details',
    'challenge',
    'report_page'
  ]);
  const dispatch = useDispatch();
  const { groupsAmbassador = [] } = useSelector(selectGroupAmbassador);
  const { company } = useSelector(selectCompany);
  const [visible, setVisible] = useState({
    totalValorization: false,
    followersAccountsParticipating: false,
    estimatedFollowers: false
  });
  const [networksBoxItems, setNetworksBoxItems] = useState([
    { linked: true, network: 'facebook', percentage: '0' },
    { linked: true, network: 'twitter', percentage: '0' },
    { linked: true, network: 'linkedin', percentage: '0' },
    { linked: true, network: 'instagram', percentage: '0' },
    { linked: true, network: 'tiktok', percentage: '0' },
    { linked: true, network: 'snapchat', percentage: '0' },
    { linked: true, network: 'youtube', percentage: '0' },
    { linked: true, network: 'threads', percentage: '0' },
    { linked: true, network: 'otros', percentage: '0' }
  ]);
  const subdomain = getCurrentCompany();
  const changeTooltip = (tooltipType: string, state: boolean) => {
    const tooltipTypes = {
      ...visible,
      [tooltipType]: state
    };
    setVisible(tooltipTypes);
  };

  const getGroup = async (name: string) => {
    await getGroupAmbassador(currentUser?.token, 1, 500, name)
      .then(response => {
        const group = sortGroups(response.data.data.groups.docs);
        const ambassadors = sortGroups(response.data.data.ambassadors.docs);
        const groupAmbassador: IGroupAmssador = {
          groups: group,
          ambassador: ambassadors
        };
        dispatch(setData(groupAmbassador));
        dispatch(setLoadingGroupAmbassador(false));
      })
      .catch(error => {
        dispatch(setLoadingGroupAmbassador(false));
      });
  };

  useEffect(() => {
    dispatch(setLoadingGroupAmbassador(true));
    validatePercentageContentBySocialNetwork(challenge);
    getGroup('');
  }, []);

  const validatePercentageContentBySocialNetwork = (challenge: any) => {
    const result: any[] = Object.keys(
      challenge.percentageContentBySocialNetwork
    ).map((key: string) => {
      return {
        linked: challenge[key],
        network: key,
        percentage: challenge?.percentageContentBySocialNetwork[key]?.toString()
      };
    });
    setNetworksBoxItems(result);
  };

  const addDefaultPicture = (evt: SyntheticEvent<HTMLImageElement, Event>) => {
    evt.currentTarget.src = EmployeeAvatar;
  };

  const timeLine = (startDate: any, finishDate: any) => {
    startDate = moment(startDate);
    const startMonth = startDate.format('MMM');
    const startDay = startDate.format('D');
    finishDate = moment(finishDate);
    const finishMonth = finishDate.format('MMM');
    const finishDay = finishDate.format('D');
    return `${startMonth} ${startDay} - ${finishMonth} ${finishDay}`;
  };
  const downloadReport = async (typeReport: string) => {
    const { data } = await getReport(
      currentUser?.token,
      challenge?.id,
      typeReport,
      null
    );
    if (data.data) {
      setSuccessAlertVisible(true);
    } else {
      setUpdateError(t('report_page:error_page'));
    }
  };
  const manualReport = async (typeReport: string) => {
    const { data } = await createManualReport(
      currentUser?.token,
      challenge?.id,
      typeReport
    );
    if (data.data) {
      setSuccessReminderAlertVisible(true);
    } else {
      setUpdateError(t('report_page:error_page'));
    }
  };

  return (
    <>
      {updateError && (
        <ErrorAlert
          text={updateError}
          allowClose={true}
          onClose={() => setUpdateError(null)}
        />
      )}
      {successAlertVisible && (
        <SuccessAlert
          text={t('report_page:success_email')}
          allowClose={true}
          onClose={() => setSuccessAlertVisible(false)}
        />
      )}
      {successReminderAlertVisible && (
        <SuccessAlert
          text={t('report_page:reminder_success')}
          allowClose={true}
          onClose={() => setSuccessReminderAlertVisible(false)}
        />
      )}
      <HeardMargin>
        <img
          alt="challengePhoto"
          src={challenge?.photo}
          className="margin_img"
        />
        <div className="margin_container">
          <span>
            <Icon size="small" name="calendar-o" color="#bd10e0" />
            {timeLine(challenge?.startDate, challenge?.finishDate)}
          </span>
          <h1>{challenge?.name}</h1>
          <p className="description">{challenge?.content}</p>
          <div>
            <h4>{t('admin_campaign_details:participating_groups')}</h4>
            <div className="name_tag_container">
              {Object.values(challenge?.groupsIds).map((group: any) => (
                <span className="name_tag" key={group}>
                  <span className="tag">
                    {Object.values(groupsAmbassador?.groups).map(
                      (groups: any) => groups?.id == group && groups?.name
                    )}
                  </span>
                </span>
              ))}
            </div>
          </div>
          <div>
            <h4>{t('admin_campaign_details:people')}</h4>
            <div className="people-tags">
              {allParticipants
                .slice(0, 5)
                .map((participant: any, index: number) => (
                  <img
                    alt="participantPhoto"
                    key={index}
                    src={participant.photo || EmployeeAvatar}
                    className="circle_people"
                    onError={evt => addDefaultPicture(evt)}
                  />
                ))}
              {allParticipants.slice(4, allParticipants.length).length > 0 && (
                <div className="circle-tag plus-tag">
                  +{allParticipants.slice(5, allParticipants.length).length}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="action-button">
              <Icon size="small" name="circle-info" color="#05559C" />
              <a href={`/challenges/${challenge?.id}/edit`}>
                {t('challenge:challenge_details')}
              </a>
              {challenge?.status === 'activated' && (
                <>
                  <div className="vl"></div>
                  <Icon size="small" name="whatsapp" color="#05559C" />
                  <a
                    href={`https://api.whatsapp.com/send/?phone&text=${t(
                      'challenge:new_challenge_avaiable'
                    )}-https://${subdomain}.advocatespro.com/challenges/detail/${
                      challenge?.id
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('challenge:share_whatsapp')}
                  </a>
                </>
              )}
              {challenge?.notifiedReminded === false &&
                challenge?.status === 'activated' && (
                  <>
                    <div className="vl"></div>
                    <Icon size="small" name="bell" color="#05559C" />
                    <span onClick={() => manualReport('challenge')}>
                      {t('admin_campaign_details:send_reminder')}
                    </span>
                  </>
                )}
            </div>
          </div>
        </div>
      </HeardMargin>
      <h1>{t('admin_campaign_details:summary')}</h1>
      <BodyWrapper>
        <MetricsWrapper className="metrics-detail">
          <StyledTooltipWrapper
            visible={false}
            onMouseOver={() => false}
            onMouseOut={() => false}
            className="tooltip-wrapper"
          >
            <SingleDataBox>
              <MetricImgBox
                iconName={iconPerson}
                value={`${challenge?.ambassadorsParticipating} / ${challenge?.totalAmbassadors}`}
                text={t('admin_campaign_details:ambassadors_generate_content')}
              />
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper
            visible={false}
            onMouseOver={() => false}
            onMouseOut={() => false}
            className="tooltip-wrapper"
          >
            <SingleDataBox>
              <MetricBox
                iconName="report"
                value={challenge?.totalParticipation}
                text={t('challenge:total_contents')}
              />
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper
            visible={visible.totalValorization}
            onMouseOver={() => changeTooltip('totalValorization', true)}
            onMouseOut={() => changeTooltip('totalValorization', false)}
            className="tooltip-wrapper"
          >
            <SingleDataBox>
              <MetricImgBox
                iconName={iconMoney}
                value={`${company?.currency?.sign}${nFormatter(
                  challenge?.totalValorization
                )}`}
                money={company?.currency?.code}
                text={t('challenge:total_valorization_singular')}
              />
              <TooltipMessage>
                {t('challenge:total_valorization_tooltip')}
              </TooltipMessage>
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper
            visible={visible.followersAccountsParticipating}
            onMouseOver={() =>
              changeTooltip('followersAccountsParticipating', true)
            }
            onMouseOut={() =>
              changeTooltip('followersAccountsParticipating', false)
            }
            className="tooltip-wrapper"
          >
            <SingleDataBox>
              <MetricBox
                iconName="total-reach"
                value={`${nFormatter(challenge?.potentialReach)}`}
                text={t('challenge:potential_reach_singular')}
              />
              <TooltipMessage>
                {t('challenge:potential_reach_tooltip')}
              </TooltipMessage>
            </SingleDataBox>
          </StyledTooltipWrapper>
          <StyledTooltipWrapper
            visible={visible.estimatedFollowers}
            onMouseOver={() => changeTooltip('estimatedFollowers', true)}
            onMouseOut={() => changeTooltip('estimatedFollowers', false)}
            className="tooltip-wrapper"
          >
            <SingleDataBox>
              <MetricImgBox
                iconName={iconHeart}
                value={`${nFormatter(challenge?.estimatedReach)}`}
                text={t('challenge:estimated_reach_singular')}
              />
              <TooltipMessage>
                {t('challenge:estimated_reach_tooltip')}
              </TooltipMessage>
            </SingleDataBox>
          </StyledTooltipWrapper>

          <StyledTooltipWrapper
            visible={false}
            onMouseOver={() => false}
            onMouseOut={() => false}
            className="tooltip-wrapper"
          >
            <SingleDataBox>
              <NetworkParticipationBox
                onConnect={() => alert('Maybe some other time')}
                items={networksBoxItems}
                detailCampaign={true}
                columns={2}
              />
            </SingleDataBox>
          </StyledTooltipWrapper>
        </MetricsWrapper>
      </BodyWrapper>
      {challenge.bonusList.length === 0 ? (
        <></>
      ) : (
        <>
          {' '}
          <StyleTable>
            <DetailChallengeTable
              contentList={challenge?.bonusList}
              socialNetworks={socialNetworks}
            ></DetailChallengeTable>
          </StyleTable>
          <ButtonStyled>
            <Button
              onClick={() => downloadReport('CHALLENGE')}
              value={t('report_page:generate_report')}
              variant="primary"
            />
          </ButtonStyled>
        </>
      )}
    </>
  );
};

export default DetailChallengeAdmin;
