import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBonus } from 'services/rest/Bonus/getBonus';
import {
  selectUser,
  selectUserInfo,
  setUserInfo
} from 'redux/slices/userSlice';
import { getInfoUser } from 'services/rest/logout';

export function CountPendingBonus() {
  const { currentUser } = useSelector(selectUser);
  const [bonus, setBonus] = useState(0);

  const getData = async () => {
    const { data } = await getBonus(currentUser?.token, 2500, 1);
    if (data) {
      const totalPending = data.data.docs.filter(
        (bonus: any) => bonus.status === 'pending'
      ).length;
      setBonus(totalPending);
    }
  };

  useEffect(() => {
    getData();
  }, [currentUser]);

  return bonus;
}

export function AllowBonus() {
  const { currentUser } = useSelector(selectUser);
  const { infoUser } = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserData = async () => {
      if (Object.keys(currentUser).length > 0) {
        const { data } = await getInfoUser(
          currentUser?.user?.id,
          currentUser?.token
        );
        if (data) {
          dispatch(setUserInfo(data));
        }
      }
    };

    if (currentUser.role !== 'admin') {
      getUserData();
    }
  }, [currentUser, dispatch]);

  if (currentUser.role !== 'admin') {
    return infoUser?.allowBonus ?? false;
  }

  return true;
}
