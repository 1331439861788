import Client from 'config/axios';

export const getCampaingsActived = async (
  token: string,
  active: boolean,
  type?: string,
  startDate?: any,
  finishDate?: any
) => {
  const paramType = type ? `type=${type}` : '';
  const paramStartDate =
    type == 'custom' && startDate != undefined ? `&startDate=${startDate}` : '';
  const paramEndDate =
    type == 'custom' && finishDate != undefined
      ? `&finishDate=${finishDate}`
      : '';
  const { data } = await Client.get(
    `campaigns-by-ambassador?${paramType}${paramStartDate}${paramEndDate}`,
    {
      params: {
        onlyActive: active,
        type: type ? type : 'recent'
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return {
    data
  };
};
