import React, { useState } from 'react';

import { ErrorAlert, SuccessAlert } from 'components/Alert';
import Input from 'components/inputs/Input';
import { ErrorSpan } from 'components/inputs/UploadFile/StyleUploadFile';
import Spinner from 'components/Spinner/Spinner';
import iconEmail from 'images/icons/email.png';
import SectionWrapper from 'pages/Layout/SectionWrapper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/slices/userSlice';
import { getReport } from 'services/rest/Report/getReport';
import Swal from 'sweetalert2';

import {
  ContentBlock,
  BackgroundImg,
  CheckboxContainer,
  MarginButtonDownload,
  MarginDescription,
  SectionCard,
  TextCheckbox,
  FiltersForm
} from './Styled';
import DropdownSelect from 'components/inputs/DropdownSelect/DropdownSelect';
import moment from 'moment';
import { selectCompany } from 'redux/slices/Company/CompanySlice';

const ReportsAdminPage = () => {
  const { t } = useTranslation(['group_page', 'report_page', 'dates']);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const emailPlatform = '';
  const [emailEmployee, setEmailEmployee] = useState('');
  const [validEmailEmployee, setValidEmailEmployee] = useState(false);
  const emailParticipation = '';
  const [check, setCheck] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
  const [loader, setLoader] = useState({
    PLATFORM: false,
    AMBASSADORS: false,
    CREATOR: false,
    EMPLOYEES: false,
    PREVIEW_EMPLOYEE: false,
    BONUS: false,
    PARTICIPATIONS_BY_DATE: false,
    MONTHLY_AMBASSADORS: false
  });
  const { currentUser } = useSelector(selectUser);
  const { company } = useSelector(selectCompany);

  const currentMonth = moment().format('M') as any;
  const monthsData = t('dates:month', {
    returnObjects: true,
    defaultValue: []
  });

  const months = Array.isArray(monthsData)
    ? monthsData.map((month, index) => ({
        label: month,
        value: index + 1
      }))
    : [];
  const years = [
    moment().year() as any,
    (moment().year() - 1) as any,
    (moment().year() - 2) as any,
    (moment().year() - 3) as any,
    (moment().year() - 4) as any,
    (moment().year() - 5) as any
  ].map(label => ({ label, value: label }));
  const [year, setYear] = useState<any>(years[0]);
  const [month, setMonth] = useState<any>(months[currentMonth - 1]);
  const [yearAmbassador, setYearAmbassador] = useState<any>(years[0]);
  const [monthAmbassador, setMonthAmbassador] = useState<any>(
    months[currentMonth - 1]
  );

  const onSubmit = async (typeReport: string, sendEmail?: string) => {
    if (typeReport === 'PREVIEW_EMPLOYEE' && !validEmailEmployee) {
      validationEmailRegex(emailEmployee, 'EMPLOYEES');
      return false;
    }

    const loaderSelected = loader as any;
    const loaderType = {
      ...loaderSelected,
      [typeReport]: true
    };
    setLoader(loaderType);

    const { data } = await getReport(
      currentUser?.token,
      '0',
      typeReport,
      sendEmail,
      typeReport === 'PARTICIPATIONS_BY_DATE'
        ? month.value
        : typeReport === 'MONTHLY_AMBASSADORS'
        ? monthAmbassador.value
        : undefined,
      typeReport === 'PARTICIPATIONS_BY_DATE'
        ? year.value
        : typeReport === 'MONTHLY_AMBASSADORS'
        ? yearAmbassador.value
        : undefined
    );
    processResponse(data.data);

    const loaderTypeFalse = {
      ...loaderSelected,
      [typeReport]: false
    };
    setLoader(loaderTypeFalse);
  };

  const validationEmailRegex = (email: any, typeContent: any) => {
    const validationEmail = regextValidationEmail(email);
    if (typeContent === 'EMPLOYEES') {
      setEmailEmployee(email);
      setValidEmailEmployee(validationEmail);
    } else {
      setUpdateError('typeContent error');
    }
  };

  const processResponse = (data: any) => {
    if (data) {
      setSuccessAlertVisible(true);
    } else {
      setUpdateError(t('report_page:error_page'));
    }
  };

  const regextValidationEmail = (email: string) => {
    const regex = new RegExp(emailRegex);
    return regex.test(email);
  };

  const handleCheck = (value: any) => {
    value ? setCheck(true) : setCheck(false);
    if (value && emailEmployee != '') {
      const validation = regextValidationEmail(emailEmployee);
      if (!validation) {
        setValidEmailEmployee(true);
      } else {
        setValidEmailEmployee(false);
      }
    } else {
      setValidEmailEmployee(false);
    }
  };

  const confirmAlert = (check: any) => {
    let typeButton: string;
    if (check) {
      typeButton = 'PREVIEW_EMPLOYEE';
    } else {
      typeButton = 'EMPLOYEES';
    }
    Swal.fire({
      text: t('report_page:send_report'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('group_page:yes'),
      cancelButtonText: t('group_page:not'),
      reverseButtons: true
    }).then(result => {
      if (result.isConfirmed) {
        onSubmit(typeButton, check ? emailEmployee : undefined);
      }
    });
  };

  return (
    <>
      {updateError && (
        <ErrorAlert
          text={updateError}
          allowClose={true}
          onClose={() => setUpdateError(null)}
        />
      )}
      {successAlertVisible && (
        <SuccessAlert
          text={t('report_page:success_email')}
          allowClose={true}
          onClose={() => setSuccessAlertVisible(false)}
        />
      )}
      <SectionWrapper>
        <h1>{t('report_page:reports')}</h1>
        <SectionCard>
          <BackgroundImg className="blue-bck">
            <h3>
              {t('report_page:report_of')}
              <strong>{t('report_page:ambassadors_report')}</strong>
            </h3>
          </BackgroundImg>
          <ContentBlock>
            <MarginDescription>
              <p>
                <strong>{t('report_page:download_all_ambassadors')}</strong>
              </p>
              <p className="subTitleAmbassadors">
                {t('report_page:registered_ambassadors')}
              </p>
            </MarginDescription>
            <MarginButtonDownload>
              <div
                className="iconDownload"
                onClick={() => onSubmit('AMBASSADORS')}
              >
                {!loader.AMBASSADORS ? (
                  <img alt="iconEmail" src={iconEmail} />
                ) : (
                  <Spinner name="sign-up" />
                )}
              </div>
            </MarginButtonDownload>
          </ContentBlock>
        </SectionCard>
        <SectionCard>
          <BackgroundImg className="purpure-bck">
            <h3>
              {t('report_page:report_of')}
              <strong>{t('report_page:platform_report')}</strong>
            </h3>
          </BackgroundImg>
          <ContentBlock>
            <MarginDescription>
              <p>
                <strong>{t('report_page:download_all_the_information')}</strong>
              </p>
              <p>{t('report_page:participating_ambassadors')}</p>
            </MarginDescription>
            <MarginButtonDownload>
              <div
                className="iconDownload"
                onClick={() => onSubmit('PLATFORM', emailPlatform)}
              >
                {!loader.PLATFORM ? (
                  <img alt="iconEmail" src={iconEmail} />
                ) : (
                  <Spinner name="sign-up" />
                )}
              </div>
            </MarginButtonDownload>
          </ContentBlock>
        </SectionCard>
        <SectionCard>
          <BackgroundImg className="blue-bck">
            <h3>
              {t('report_page:report_of')}
              <strong>{t('report_page:report_challenge')}</strong>
            </h3>
          </BackgroundImg>
          <ContentBlock>
            <MarginDescription>
              <p>
                <strong>{t('report_page:download_all_challenges')}</strong>
              </p>
              <p>{t('report_page:participating_challenges')}</p>
            </MarginDescription>
            <MarginButtonDownload>
              <div className="iconDownload" onClick={() => onSubmit('CREATOR')}>
                {!loader.CREATOR ? (
                  <img alt="iconEmail" src={iconEmail} />
                ) : (
                  <Spinner name="sign-up" />
                )}
              </div>
            </MarginButtonDownload>
          </ContentBlock>
        </SectionCard>
        <SectionCard>
          <BackgroundImg className="purpure-bck">
            <h3>
              {t('report_page:report_of')}
              <strong>{t('report_page:bonus_report')}</strong>
            </h3>
          </BackgroundImg>
          <ContentBlock>
            <MarginDescription>
              <p>
                <strong>{t('report_page:download_bonus')}</strong>
              </p>
              <p>{t('report_page:bonus_description')}</p>
            </MarginDescription>
            <MarginButtonDownload>
              <div className="iconDownload" onClick={() => onSubmit('BONUS')}>
                {!loader.BONUS ? (
                  <img alt="iconEmail" src={iconEmail} />
                ) : (
                  <Spinner name="sign-up" />
                )}
              </div>
            </MarginButtonDownload>
          </ContentBlock>
        </SectionCard>
        <SectionCard>
          <BackgroundImg className="blue-bck">
            <h3>
              {t('report_page:report_month')}
              <strong>{t('report_page:ambassadors_report')}</strong>
            </h3>
          </BackgroundImg>
          <ContentBlock>
            <MarginDescription>
              <p>
                <strong>{t('report_page:month_report')}</strong>
              </p>
              <FiltersForm>
                <DropdownSelect
                  onSelect={e => {
                    setMonthAmbassador(e);
                  }}
                  value={monthAmbassador}
                  defaultValue={monthAmbassador}
                  options={months}
                />
                <DropdownSelect
                  onSelect={e => {
                    setYearAmbassador(e);
                  }}
                  value={yearAmbassador}
                  defaultValue={yearAmbassador}
                  options={years}
                />
              </FiltersForm>
            </MarginDescription>
            <MarginButtonDownload>
              <div
                className="iconDownload"
                onClick={() =>
                  onSubmit('MONTHLY_AMBASSADORS', emailParticipation)
                }
              >
                {!loader.MONTHLY_AMBASSADORS ? (
                  <img alt="iconEmail" src={iconEmail} />
                ) : (
                  <Spinner name="sign-up" />
                )}
              </div>
            </MarginButtonDownload>
          </ContentBlock>
        </SectionCard>
        {company?.company_permission.allowReportParticipation && (
          <SectionCard>
            <BackgroundImg className="purpure-bck">
              <h3>
                {t('report_page:report_of')}
                <strong>{t('report_page:participation_report')}</strong>
              </h3>
            </BackgroundImg>
            <ContentBlock>
              <MarginDescription>
                <p>
                  <strong>{t('report_page:participation_info')}</strong>{' '}
                  {t('report_page:participation_ambassadors')}
                </p>
                <FiltersForm>
                  <DropdownSelect
                    onSelect={e => {
                      setMonth(e);
                    }}
                    value={month}
                    defaultValue={month}
                    options={months}
                  />
                  <DropdownSelect
                    onSelect={e => {
                      setYear(e);
                    }}
                    value={year}
                    defaultValue={year}
                    options={years}
                  />
                </FiltersForm>
              </MarginDescription>
              <MarginButtonDownload>
                <div
                  className="iconDownload"
                  onClick={() =>
                    onSubmit('PARTICIPATIONS_BY_DATE', emailParticipation)
                  }
                >
                  {!loader.PARTICIPATIONS_BY_DATE ? (
                    <img alt="iconEmail" src={iconEmail} />
                  ) : (
                    <Spinner name="sign-up" />
                  )}
                </div>
              </MarginButtonDownload>
            </ContentBlock>
          </SectionCard>
        )}
        <SectionCard>
          <BackgroundImg className="blue-bck">
            <h3>
              {t('report_page:send_email_of')}
              <strong>{t('report_page:ambassador_month')}</strong>
            </h3>
          </BackgroundImg>
          <ContentBlock>
            <MarginDescription>
              <p className="titleSendAmbassadors">
                <strong>{t('report_page:send_all_ambassadors')}</strong>
              </p>
              <CheckboxContainer>
                <TextCheckbox>{t('report_page:test_mail')} </TextCheckbox>
                <Input
                  type="checkbox"
                  onChange={e => {
                    handleCheck(e.target.checked);
                  }}
                  checked={check}
                />
                <Input
                  className="inputEmail"
                  type={check ? 'text' : 'hidden'}
                  value={emailEmployee}
                  onChange={e => {
                    validationEmailRegex(e.target.value, 'EMPLOYEES');
                  }}
                  placeholder={t('example')}
                />
              </CheckboxContainer>
              <p className="textDespriction" hidden={!check}>
                {t('report_page:from_email')}
              </p>
              {check && !validEmailEmployee && (
                <ErrorSpan id="error_message_report">
                  {t('report_page:error_send_email')}
                </ErrorSpan>
              )}
            </MarginDescription>
            <MarginButtonDownload>
              <div className="iconDownload" onClick={() => confirmAlert(check)}>
                {!loader.EMPLOYEES || !loader.PREVIEW_EMPLOYEE ? (
                  <img alt="iconEmail" src={iconEmail} />
                ) : (
                  <Spinner name="sign-up" />
                )}
              </div>
            </MarginButtonDownload>
          </ContentBlock>
        </SectionCard>
      </SectionWrapper>
    </>
  );
};

export default ReportsAdminPage;
