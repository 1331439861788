import React from 'react';

const CustomHashtag = (props: any) => {
  return (
    <span style={{ color: '#05559C', fontSize: '14px', fontWeight: 'bold' }}>
      {props.children}
    </span>
  );
};

export default CustomHashtag;
